const dev = {
    API_ENDPOINT_SERVER_MAFFLE: 'https://12vrfdnrqg.execute-api.eu-south-1.amazonaws.com',
    API_ENDPOINT_URL: 'https://0pb037nemd.execute-api.eu-south-1.amazonaws.com',
    API_AUTH_URL: 'https://cognito-idp.eu-south-1.amazonaws.com',
    aws_cognito_region: 'eu-south-1',
    aws_user_pools_web_client_id: '7t7uct7vucfshgf9nhvf51voa2',
    STRIPE: {
        PRICE_LICENZA: 'price_1NyVJPJ5Q4L2IzvQClcxV0dP',
        PREZZO_UNICO: 2500
    }
};

const coll = {
    API_ENDPOINT_SERVER_MAFFLE: 'https://4ne60i8enb.execute-api.eu-south-1.amazonaws.com',
    API_ENDPOINT_URL: 'https://1mc6493gme.execute-api.eu-south-1.amazonaws.com',
    API_AUTH_URL: 'https://cognito-idp.eu-south-1.amazonaws.com',
    aws_cognito_region: 'eu-south-1',
    aws_user_pools_web_client_id: '4854g8h4d9t58hlhdbsig79his',
    STRIPE: {
        PRICE_LICENZA: 'price_1QfkiZQtykBPhbCvpidPyRJf',
        PREZZO_UNICO: 2500
    }
};

const prod = {
    API_ENDPOINT_SERVER_MAFFLE: 'https://0d0zxbeiee.execute-api.eu-south-1.amazonaws.com',
    API_ENDPOINT_URL: 'https://dncnkt49l2.execute-api.eu-south-1.amazonaws.com',
    API_AUTH_URL: 'https://cognito-idp.eu-south-1.amazonaws.com',
    aws_cognito_region: 'eu-south-1',
    aws_user_pools_web_client_id: '1je87ggttpi5s2v6ikr42vg9sk',
    STRIPE: {
        PRICE_LICENZA: 'price_1O5qRzJ5Q4L2IzvQN2wBA5Po',
        PREZZO_UNICO: 2500
    }
};

const getEnv = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'sviluppo':
            return dev
        case 'collaudo':
            return coll
        case 'produzione':
            return prod
        default:
            return {
                API_ENDPOINT_SERVER_MAFFLE: 'http://localhost:8081',
                API_ENDPOINT_URL: 'http://localhost:8080',
                API_AUTH_URL: 'https://cognito-idp.eu-south-1.amazonaws.com',
                aws_cognito_region: 'eu-south-1',
                aws_user_pools_web_client_id: '7t7uct7vucfshgf9nhvf51voa2',
                STRIPE: {
                    PRICE_LICENZA: 'price_1NyVJPJ5Q4L2IzvQClcxV0dP',
                    PREZZO_UNICO: 2500
                }
            };
    }
}

export const env = getEnv()
